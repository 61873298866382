import styled from "styled-components"
import { Link } from "gatsby"

export const Main = styled.div`
  margin-top: 40px;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1149px) {
    height: auto;
  }
`

export const Wrapper = styled.div`
  width: 83.3%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1149px) {
    width: 82.5%;
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
  }

  @media (max-width: 799px) {
    width: 88.88%;
    justify-content: center;
  }
`

export const BlackButton = styled(Link)`
  cursor: pointer;
  border: none;
  width: calc(25% - 20px);
  height: 60px;
  background: #111111;
  border-radius: 2px;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2.1rem;
  letter-spacing: 0.06em;
  color: #fdfdfd;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  @media (min-width: 1400px) {
    width: calc(25% - 40px);
  }

  @media (max-width: 1149px) {
    width: calc(50% - 10px);
  }

  @media (max-width: 799px) {
    width: 100%;
  }
`
