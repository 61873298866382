import React from "react"
import { Main, TextArea } from "./style"
import { FakeScrollBox } from "../style"
import { Title } from "../style"

const DescriptionComponent = ({ data, description }) => {
  return (
    <Main>
      <FakeScrollBox id="description" />
      <Title>{description}:</Title>
      <TextArea>
        {data.baseData ? data.baseData.description : "Nie podano"}
      </TextArea>
    </Main>
  )
}

export default DescriptionComponent
