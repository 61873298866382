import styled, { css } from "styled-components"
import { GoldButton } from "../../SearchComponent/style"
import lion from "../../../images/lion1.png"
import { Link } from "gatsby-plugin-react-intl"

export const Main = styled.div`
  position: relative;

  background: url(${lion}) top right no-repeat;
  background-size: auto 100%;

  @media (max-width: 799px) {
    background: none;
  }
`

export const MainForm = styled.form`
  width: 83.3%;

  margin: 74px auto 0;
  padding-bottom: 237px;

  @media (max-width: 1149px) {
    width: 82.5%;
  }

  @media (max-width: 799px) {
    width: 88.888%;
  }

  * {
    font-size: 1.4rem;
    color: #fff;
  }
`

export const MainHeader = styled.header`
  background: linear-gradient(
    90deg,
    #ce9638 5.35%,
    #eae69c 17.05%,
    #b6872d 114.34%
  );

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  font-size: 2.3rem;
  line-height: 3.45rem;
  letter-spacing: 0.06em;
`

export const FormMessage = styled.div`
  margin: 15px 0;
`

export const FormMessageSpecial = styled.div`
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2.1rem;
  letter-spacing: 0em;

  margin-bottom: 10px;
`

export const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 60px 262px auto auto;
  grid-template-areas:
    "name phone mail"
    "message message message"
    "rodotext rodotext rodotext"
    "form-submit form-submit form-submit";
  gap: 20px;

  @media (max-width: 1149px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 60px 60px 262px auto auto;
    grid-template-areas:
      "name phone"
      "mail mail"
      "message message"
      "rodotext rodotext"
      "form-submit form-submit";
  }

  @media (max-width: 799px) {
    grid-template-columns: 1fr;
    grid-template-rows: 60px 60px 60px 262px auto auto;
    grid-template-areas:
      "name"
      "phone"
      "mail"
      "message"
      "rodotext"
      "form-submit";
  }

  #filename {
    grid-area: filename;
  }

  #rodotext {
    grid-area: rodotext;
  }

  #name {
    grid-area: name;
  }

  #phone {
    grid-area: phone;
  }

  #mail {
    grid-area: mail;
  }

  #message {
    grid-area: message;
  }

  #form-submit {
    grid-area: form-submit;
  }
`

export const StyledInput = styled.input`
  border: none;
  background: rgba(17, 17, 17, 0.75);
  color: #fdfdfd;
  border-radius: 2px;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.1rem;
  letter-spacing: 0.06em;
  padding: 20px 15px 19px 15px;
  outline: none;

  &::placeholder {
    color: #fdfdfd;
    opacity: 1;
  }

  &:focus::placeholder {
    color: transparent;
  }
`

export const StyledTextArea = styled.textarea`
  resize: none;
  border: none;
  background: rgba(17, 17, 17, 0.75);
  color: #fdfdfd;
  border-radius: 2px;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.1rem;
  letter-spacing: 0.06em;
  padding: 20px 15px 19px 15px;
  outline: none;

  &::placeholder {
    color: #fdfdfd;
    opacity: 1;
  }

  &:focus::placeholder {
    color: transparent;
  }
`

export const CheckBoxDiv = styled.div`
  width: 21px;
  height: 21px;
  display: inline-flex;
  align-items: center;
  border-radius: 15px;

  cursor: pointer;
  justify-content: center;
  background: #111111;

  &:after {
    content: "";
    width: 10.5px;
    height: 10.5px;
    border-radius: 15px;
    box-shadow: 0px 0px 15px 3px #bb913c;
    transition: all 0.15s;

    background: linear-gradient(
      134deg,
      #ce9638 0%,
      #eae69c 30.4%,
      #ce9638 64.44%,
      #b6872d 77.6%,
      #bb913c 100%
    );

    ${props =>
      props.isChecked
        ? css``
        : css`
            opacity: 0;
          `}
  }
`

export const CheckBoxText = styled.span`
  letter-spacing: 0;
  color: #fdfdfd;
  font-family: Poppins;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.3rem;
  letter-spacing: 0;
  text-align: justify;
  opacity: 0.8;
`

export const StyledGoldButton = styled(GoldButton)`
  color: #030303;
  float: right;
`

export const FormSubmit = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;

  @media (max-width: 799px) {
    display: flex;
    flex-direction: column;
  }
`

export const CVBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column-start: 6;
  grid-column-end: 9;
  grid-row-start: 4;
  grid-row-end: 5;
  gap: 11px;
  cursor: pointer;

  @media (max-width: 1149px) {
    grid-column-start: 7;
    grid-column-end: 9;
    grid-row-start: 3;
    grid-row-end: 4;
  }

  @media (max-width: 799px) {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 6;
    grid-row-end: 7;
    justify-content: flex-start;
    align-items: center;
  }

  p {
    color: #bb913c;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 2.1rem;
    letter-spacing: 0;
  }

  img {
    width: 17px;
    height: 17px;
  }
`

export const FileNameBox = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  user-select: none;
  cursor: pointer;

  p {
    color: #bb913c;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 2.1rem;
    letter-spacing: 0;
  }

  img {
    height: 12px;
    width: 12px;
    margin-bottom: 4px;
    cursor: pointer;
  }
`

export const RodoLink = styled(Link)`
  letter-spacing: 0;
  color: #fdfdfd;
  font-family: Poppins;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.3rem;
  letter-spacing: 0;
  text-align: justify;
  text-decoration: underline;
  cursor: pointer;
`
