import React from "react"
import { Main } from "./style"
import { Title } from "../style"
import GoogleMapsEmbed from "../../../GoogleMapsEmbed"
import { FakeScrollBox } from "../style"

const MapComponent = ({ data, location }) => {
  const address = `${
    data.baseData.address.city ? data.baseData.address.city + "," : ""
  } ${
    data.baseData.address.district ? data.baseData.address.district + "," : ""
  } ${data.baseData.address.state ? data.baseData.address.state : ""}`

  return (
    <Main>
      <FakeScrollBox id="location" />
      <Title>{location}:</Title>
      <GoogleMapsEmbed query={address} />
    </Main>
  )
}

export default MapComponent
