import React, { useContext, useRef, useState, useEffect } from "react"
import { Main, Wrapper, LeftSide, RightSide } from "./style"
import ButtonsBar from "./ButtonsBar"
import MapComponent from "./MapComponent"
import DescriptionComponent from "./DescriptionComponent"
import ImagesComponent from "./ImagesComponent"
import DetailsComponent from "./DetailsComponent"
import AgentBox from "../AgentBox"
import NavBar from "./NavBar"
import { ResolutionContext } from "../../../contexts/ResolutionContext"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-react-intl"

const FlatDescription = ({ data, handleClick }) => {
  const [isTablet, isTabletAndPhone] = useContext(ResolutionContext)
  const pdfRef = useRef(null)
  const intl = useIntl()
  const [data2, setData2] = useState({})
  const isBrowser = typeof window !== "undefined"

  const query = useStaticQuery(graphql`
    query Offer {
      polish: contentfulOffer(node_locale: { eq: "pl-PL" }) {
        agent
        ask_for_contact
        call
        description
        download_offer
        informations
        location
        photos
      }

      english: contentfulOffer(node_locale: { eq: "en-US" }) {
        agent
        ask_for_contact
        call
        description
        download_offer
        informations
        location
        photos
      }
    }
  `)

  useEffect(() => {
    if (isBrowser) {
      if (intl.locale === "en") {
        setData2(query.english)
      }
      if (intl.locale === "pl") {
        setData2(query.polish)
      }
    }
  }, [intl])

  if (typeof window === `undefined`) {
    return <></>
  }

  return (
    <Main>
      <NavBar
        photos={data2.photos}
        location={data2.location}
        description={data2.description}
        informations={data2.informations}
      />
      <Wrapper id="pdf-box" ref={pdfRef}>
        <LeftSide>
          {isTabletAndPhone && <AgentBox data={data} agent={data2.agent} />}
          <DetailsComponent data={data} />
          <ImagesComponent
            data={data}
            photos={data2.photos}
            handleClick={handleClick}
          />
          <DescriptionComponent data={data} description={data2.description} />
          <MapComponent data={data} location={data2.location} />
        </LeftSide>
        {!isTabletAndPhone && (
          <RightSide>
            <AgentBox data={data} agent={data2.agent} />
          </RightSide>
        )}
      </Wrapper>
      <ButtonsBar
        data={data}
        pdfRef={pdfRef}
        ask_for_contact={data2.ask_for_contact}
        download_offer={data2.download_offer}
        call={data2.call}
      />
    </Main>
  )
}

export default FlatDescription
