import styled from "styled-components"

export const Main = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 35px auto;
  gap: 20px 20px;
  margin-top: 106px;
  position: relative;

  img {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 2;
    grid-row-end: 3;
  }
`

export const TextArea = styled.pre`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.1rem;
  letter-spacing: 0.06em;
  color: #fdfdfd;
  grid-column-start: 1;
  grid-column-end: 9;
  grid-row-start: 2;
  grid-row-end: 3;
  white-space: pre-wrap;
`
