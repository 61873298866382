import styled from "styled-components"

export const Main = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 35px auto;
  gap: 20px 20px;
  margin-top: 73px;
  position: relative;
`

export const ImagesBox = styled.div`
  grid-column-start: 1;
  grid-column-end: 9;
  grid-row-start: 2;
  grid-row-end: 3;
  display: grid;
  grid-template-columns: repeat(3, 31.5%);
  gap: 20px;

  & > img {
    width: 100%;
    aspect-ratio: 16/9;
    cursor: pointer;
    transition: all 1s;
  }

  @media (max-width: 1149px) {
    grid-template-columns: repeat(2, 50%);
  }

  @media (max-width: 799px) {
    grid-template-columns: repeat(1, 100%);
  }
`

export const SliderMain = styled.div`
  width: 100%;
  height: 100vh;
  background: black;
  position: fixed;
  margin: 0 auto;
  top: 0;
  left: 0;
  z-index: 50;
  display: flex;
  justify-content: space-between;
  /* position: relative; */

  & > img {
    width: 100%;
    aspect-ratio: 16/9;
  }
`

export const Arrow = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 100%;
  color: #fdfdfd;
  font-size: 2.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  transition: all 0.1s ease-out;
  background: rgba(0, 0, 0, 0.2);
`

export const ArrowBar = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.1s ease-out;
  cursor: pointer;
  position: absolute;
  left: 0;
  z-index: 51;

  :last-child {
    right: 0;
    left: unset;
  }

  &:hover {
    background: rgba(215, 215, 215, 0.03);
    transition: all 0.1s ease-out;

    & > ${Arrow} {
      color: #ce9638;
    }
  }
`

export const SliderImageBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  & > img {
    max-height: 100vh;
    max-width: 100%;
    position: relative;
  }
`

export const SliderSmallImages = styled.div`
  display: flex;
  gap: 30px;
  bottom: 65px;
  position: absolute;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-height: 700px) {
    bottom: 40px;
  }
`

export const SmallImage = styled.img`
  height: 50px;
  opacity: ${props => (props.index === props.selectedImage ? 1 : 0.5)};
  cursor: pointer;
  transition: all 0.15s;
  border: 1.5px solid #ce9638;

  &:hover {
    opacity: 1;
    transition: all 0.15s;
  }
`

export const CloseButton = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 100%;
  color: #fdfdfd;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.15s;
  cursor: pointer;
  user-select: none;
  position: absolute;
  top: 3.5%;
  right: 0;
  font-size: 1.8rem;
  fill: #fdfdfd;
  z-index: 52;

  svg {
    width: 18px;
    height: 18px;
    transition: all 0.15s;
  }

  &:hover {
    svg {
      fill: #ce9638;
      transition: all 0.15s;
    }
  }
`
