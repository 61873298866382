import React from "react"
import { Main, Wrapper, BlackButton } from "./style"

const NavBar = ({ photos, description, location, informations }) => {
  return (
    <Main>
      <Wrapper>
        <BlackButton to="#info">{informations}</BlackButton>

        <BlackButton to="#images">{photos}</BlackButton>

        <BlackButton to="#description">{description}</BlackButton>

        <BlackButton to="#location">{location}</BlackButton>
      </Wrapper>
    </Main>
  )
}

export default NavBar
