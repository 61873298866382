import React from "react"
import {
  Main,
  BlackButton,
  Wrapper,
  StyledGoldButton,
  StyledLink,
} from "./style"
import "jspdf/dist/polyfills.es.js"
import axios from "axios"
import { useIntl, navigate } from "gatsby-plugin-react-intl"

const ButtonsBar = ({
  data,
  pdfRef,
  ask_for_contact,
  call,
  download_offer,
}) => {
  const intl = useIntl()
  const lang = intl.locale
  const pdfName = data.baseData.meta
    ? `${data.baseData.meta.urlId}.pdf`
    : "oferta.pdf"
  const printDocument = async () => {
    const response = await axios.post(
      process.env.GATSBY_PDF_URL_API,
      {
        offer: data.baseData.meta.urlId,
      },
      {
        responseType: "blob",
      }
    )

    const url = window.URL.createObjectURL(response.data)

    const a = document.createElement("a")
    a.style.display = "none"
    a.href = url
    // the filename you want
    a.download = pdfName
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)
  }
  return (
    <Main>
      <Wrapper>
        <BlackButton onClick={() => printDocument()}>
          {download_offer}
        </BlackButton>
        <StyledLink to={lang === "pl" ? "/kontakt#form" : "contact#form"}>
          <BlackButton>{ask_for_contact}</BlackButton>
        </StyledLink>
      </Wrapper>
    </Main>
  )
}
export default ButtonsBar
