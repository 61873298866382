import React, { useEffect, useRef, useState } from "react"
import {
  Main,
  PopUpBox,
  ExitButton,
  StyledCancelButton,
  ErrorMessage,
  Wrapper,
  SuccessMessage,
} from "./style"
import { StyledInput, StyledTextArea } from "../ContactPage/FormComponent/style"
import { Title } from "../OfferPage/FlatDescription/style"
import exitButton from "../../images/popup-exit-button.png"
import { GoldButton } from "../SearchComponent/style"
import { graphql, useStaticQuery } from "gatsby"
import { useIntl } from "gatsby-plugin-react-intl"

const PopUp = ({ isOpen, toggleOpen, agentEmail }) => {
  const intl = useIntl()
  const [data, setData] = useState({})

  const query = useStaticQuery(graphql`
    query PopUpQuery {
      polish: contentfulPopup(node_locale: { eq: "pl-PL" }) {
        cancel
        email
        message
        name
        phone
        send
        sendMessage
        successMessage
        popupLongText {
          popupLongText
        }
        popupError {
          popupError
        }
      }
      english: contentfulPopup(node_locale: { eq: "en-US" }) {
        cancel
        email
        message
        name
        phone
        send
        sendMessage
        successMessage
        popupLongText {
          popupLongText
        }
        popupError {
          popupError
        }
      }
    }
  `)

  useEffect(() => {
    if (isBrowser) {
      if (intl.locale === "en") {
        setData(query.english)
      }
      if (intl.locale === "pl") {
        setData(query.polish)
      }
    }
  }, [intl])

  useEffect(() => {
    if (!isOpen) {
      document.body.style.overflowY = "visible"
    } else {
      document.body.style.overflowY = "hidden"
    }
  }, [isOpen])

  const isBrowser = typeof window !== "undefined"
  const [file, setFile] = useState(null)
  const [formErrors, setFormErrors] = useState([])
  const [formSuccess, setFormSuccess] = useState(false)
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    mail: "",
    message: "",
  })

  const updateField = (name, value) => {
    setFormData(prev => ({ ...prev, [name]: value }))
  }

  const resetForm = () => {
    setFormData({
      name: "",
      phone: "",
      mail: "",
      message: "",
    })
  }

  const sendForm = e => {
    // e.preventDefault()

    const validation = validateForm()

    if (validation !== true) {
      setFormErrors(validation)
      setFormSuccess(false)

      return false
    } else {
      setFormSuccess(true)
      setFormErrors([])
    }

    var formdata = new FormData()

    formdata.append("firstAndLastName", formData.name)
    formdata.append("message", formData.message)
    formdata.append("phoneNumber", formData.phone.replaceAll(" ", ""))
    formdata.append("email", formData.mail)
    formdata.append("offerId", window.location.href)
    formdata.append("agentEmail", agentEmail)

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
      formData: formdata,
    }

    fetch(process.env.GATSBY_CONTACT_URL_API, requestOptions)
      .then(response => response.text())
      .then(result => setFormSuccess(true))
      .then(() => resetForm())
      .catch(error => console.log("error", error))

    resetForm()

    return false
  }

  const validateForm = () => {
    const errors = []
    const isEmpty = v => v.trim() === ""
    const hasLengthBetween = (v, a, b) => v.length >= a && v.length <= b
    const isPhoneNumber = v => /[0-9\+]{9,13}/.test(v.replace(/[\s]/g, ""))
    // Regex below copied from https://emailregex.com/
    const isEmailAddress = v =>
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v
      )

    if (isEmpty(formData.name)) {
      errors.push("validatename 2") // translacja
    } else if (hasLengthBetween(formData.name, 3, 50) === false) {
      errors.push("validatename") // translacja
    }

    if (isPhoneNumber(formData.phone) === false) {
      errors.push("validatephone") // translacja
    }

    if (isEmailAddress(formData.mail) === false) {
      errors.push("validateEmail") // translacja
    }

    if (hasLengthBetween(formData.message, 10, 1000) === false) {
      errors.push("validateMessage") // translacja
    }

    if (errors.length) return errors

    return true
  }

  return (
    <Main isOpen={isOpen} onClick={() => toggleOpen(false)}>
      <Wrapper>
        <PopUpBox
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          <Title id="title">{data.sendMessage}</Title>
          <ExitButton
            alt="icon"
            src={exitButton}
            id="button"
            onClick={() => toggleOpen(false)}
          />
          <StyledInput
            id="name"
            placeholder={data.name}
            value={formData.name}
            onChange={e => updateField("name", e.target.value)}
          />
          <StyledInput
            id="phone"
            placeholder={data.phone}
            value={formData.phone}
            onChange={e => updateField("phone", e.target.value)}
          />
          <StyledInput
            id="email"
            placeholder={data.email}
            value={formData.mail}
            onChange={e => updateField("mail", e.target.value)}
          />
          <StyledTextArea
            id="textarea"
            placeholder={data.message}
            value={formData.message}
            onChange={e => updateField("message", e.target.value)}
          />

          {formErrors.length > 0 && (
            <ErrorMessage>
              <p>{data.popupError && data.popupError.popupError}</p>
            </ErrorMessage>
          )}

          {formSuccess && (
            <SuccessMessage>
              <p>{data.successMessage}</p>
            </SuccessMessage>
          )}

          <StyledCancelButton id="cancel" onClick={() => toggleOpen(false)}>
            <span>{data.cancel}</span>
          </StyledCancelButton>
          <GoldButton onClick={sendForm} id="send">
            {data.send}
          </GoldButton>
          <p>{data.popupLongText && data.popupLongText.popupLongText}</p>
        </PopUpBox>
      </Wrapper>
    </Main>
  )
}

export default PopUp
