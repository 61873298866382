import styled, { css } from "styled-components"

export const Main = styled.div`
  position: fixed;
  display: ${props => (props.isOpen ? "flex" : "none")};
  width: 100%;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 7;
  overflow-y: auto;
`

export const PopUpBox = styled.div`
  z-index: 8;
  width: 100%;
  height: 100%;
  padding: 36px;
  /* box-sizing: content-box; */
  border-radius: 5px;
  background: #030303;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 35px 60px 60px 202px auto auto auto;
  gap: 20px 20px;
  min-width: unset;

  @media (max-width: 799px) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 35px 60px 60px 60px 202px auto auto auto;
  }

  #title {
    grid-area: title;
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  #name {
    grid-area: name;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;

    @media (max-width: 799px) {
      grid-column-start: 1;
      grid-column-end: 5;
      grid-row-start: 2;
      grid-row-end: 3;
    }
  }

  #phone {
    grid-area: phone;
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 3;

    @media (max-width: 799px) {
      grid-column-start: 1;
      grid-column-end: 5;
      grid-row-start: 4;
      grid-row-end: 5;
    }
  }

  #email {
    grid-area: email;
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 3;
    grid-row-end: 4;

    @media (max-width: 799px) {
      grid-column-start: 1;
      grid-column-end: 5;
      grid-row-start: 3;
      grid-row-end: 4;
    }
  }

  #textarea {
    grid-area: textarea;
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 4;
    grid-row-end: 5;

    @media (max-width: 799px) {
      grid-column-start: 1;
      grid-column-end: 5;
      grid-row-start: 5;
      grid-row-end: 6;
    }
  }

  #cancel {
    grid-area: cancel;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row: span 1;
  }

  #send {
    grid-area: send;
    height: 60px;
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row: span 1;

    @media (max-width: 799px) {
      grid-column-start: 1;
      grid-column-end: 5;
      grid-row: span 1;
    }
  }

  & > p:last-of-type {
    grid-area: text;
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row: span 1;

    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.06em;
    text-align: justify;
    color: #fdfdfd;

    @media (max-width: 799px) {
      grid-column-start: 1;
      grid-column-end: 5;
      grid-row: span 1;
    }
  }
`

export const ExitButton = styled.img`
  height: 23.33px;
  width: 23.33px;
  grid-area: exit;
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 2;
  align-self: center;
  justify-self: flex-end;
  cursor: pointer;
`

export const StyledCancelButton = styled.button`
  span {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e6dc8f+18,b78930+81 */
    background: #e6dc8f; /* Old browsers */
    background: -moz-linear-gradient(top,  #e6dc8f 18.51%, #b78930 81.19%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  #e6dc8f 18.51%,#b78930 81.19%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  #e6dc8f 18.51%,#b78930 81.19%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6dc8f', endColorstr='#b78930',GradientType=0 ); /* IE6-9 */
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -ms-background-clip: text;
    -o-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    -ms-text-fill-color: transparent;
    -o-text-fill-color: transparent;
    text-fill-color: transparent;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 2.4rem;
    letter-spacing: 0.06em;
    text-align: center;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
  }
  
  min-width: 150px;
  border: 1px solid rgb(255, 203, 117);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ce9638;
  height: 60px;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.4rem;
  letter-spacing: 0.06em;
  text-align: center;
  text-transform: uppercase;
  color: #030303;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e6dc8f+18,b78930+81 */
  background: #e6dc8f; /* Old browsers */
  background: -moz-linear-gradient(top,  #e6dc8f 18.51%, #b78930 81.19%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #e6dc8f 18.51%,#b78930 81.19%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #e6dc8f 18.51%,#b78930 81.19%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6dc8f', endColorstr='#b78930',GradientType=0 ); /* IE6-9 */

  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  cursor: pointer;

  @media (max-width: 799px) {
    display: none;
  }
`

export const ErrorMessage = styled.div`
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 5;
  grid-row-end: 6;

  p {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.8rem;
    letter-spacing: 0.06em;
    text-align: justify;
    color: #fe0000;
  }

  @media (max-width: 799px) {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 6;
    grid-row-end: 7;
  }
`

export const SuccessMessage = styled(ErrorMessage)`
  p {
    color: #fdfdfd;
  }
`

export const Wrapper = styled.div`
  max-height: 85vh;
  margin-bottom: 50px;
  background: #030303;
  max-width: 802px;
  width: 100%;
`
