import React from "react"
import { Main, SpanBox, SubTitle, Value, StyledTitle } from "../style"
import { FakeScrollBox } from "../../style"
import { useIntl } from "gatsby-plugin-react-intl"

const House = ({ data, queryData, queryData2, queryData3 }) => {
  const intl = useIntl()
  const lang = intl.locale
  const address = `${data.baseData.address.address ? data.baseData.address.address + "," : ""} ${
    data.baseData.address.district ? data.baseData.address.district + "," : ""
  } ${
    data.baseData.address.city ? data.baseData.address.city + "," : ""
  } ${data.baseData.address.state ? data.baseData.address.state : ""}`

  const accessRoadSwitch = accessRoadType => {
    switch (accessRoadType) {
      case "asphalt":
        return queryData.asphalt

      case "hardened":
        return queryData.hardened

      case "field":
        return queryData.field

      case "lack":
        return queryData.lack

      default:
        return queryData.notReported
    }
  }

  const propertyTypeSwitch = propertyType => {
    switch (propertyType) {
      case "flat":
        return queryData.flat

      case "house":
        return queryData.house

      case "land":
        return queryData.land

      case "office_space":
        return queryData.officeSpace

      case "usable_locale":
        return queryData.usableLocale

      default:
        return queryData.notReported
    }
  }

  const landTypeSwitch = landType => {
    switch (landType) {
      case "construction":
        return queryData2.construction

      case "agricultural":
        return queryData2.agricultural

      case "investment":
        return queryData2.investment

      case "services":
        return queryData2.services

      case "recreational":
        return queryData2.recreational

      case "forest":
        return queryData2.forest

      case "habitat":
        return queryData2.habitat

      case "farm":
        return queryData2.farm

      case "craft":
        return queryData2.craft

      case "agro_construction":
        return queryData2.agroConstruction

      default:
        return queryData.notReported
    }
  }

  const fenceTypeSwitch = fenceType => {
    switch (fenceType) {
      case "metal":
        return queryData2.metal

      case "mesh":
        return queryData2.mesh

      case "brick":
        return queryData2.brick

      case "concrete":
        return queryData2.concrete

      case "wooden":
        return queryData2.wooden

      case "hedge":
        return queryData2.hedge

      case "mixed":
        return queryData2.mixed

      case "none":
        return queryData2.none

      default:
        return queryData.notReported
    }
  }

  const houseTypeSwitch = houseType => {
    switch (houseType) {
      case "detached":
        return queryData2.detached

      case "middle_segment":
        return queryData2.middleSegment

      case "extreme_segment":
        return queryData2.extremeSegment

      case "twin":
        return queryData2.twin

      case "half_twin":
        return queryData2.halfTwin

      case "tenement":
        return queryData.tenement

      case "villa":
        return queryData2.villa

      case "residence":
        return queryData2.residence

      case "manor_house":
        return queryData2.manorHouse

      case "serial":
        return queryData2.serial

      case "house_floor":
        return queryData2.houseFloor

      case "recreational":
        return queryData2.recreational

      default:
        return queryData.notReported
    }
  }

  const bathroomConditionSwitch = bathroomCondition => {
    switch (bathroomCondition) {
      case "good":
        return queryData2.good

      case "to_be_renewed":
        return queryData.toBeRenewed

      case "for_renovation":
        return queryData2.forRenovation

      case "high_standard":
        return queryData2.highStandard

      default:
        return queryData.notReported
    }
  }

  const buildingConditionSwitch = buildingCondition => {
    switch (buildingCondition) {
      case "luxury":
        return queryData2.luxury

      case "very_good":
        return queryData2.veryGood

      case "good":
        return queryData2.good

      case "for_renovation":
        return queryData2.forRenovation

      case "after_renovation":
        return queryData.afterRenovation

      case "for_finishing":
        return queryData.forFinishing

      case "renovated":
        return queryData.renovated

      case "to_be_renewed":
        return queryData.toBeRenewed

      case "open_shell":
        return queryData2.openShell

      case "closed_shell":
        return queryData2.closedShell

      case "without_white_assembly":
        return queryData2.withoutWhiteAssembly

      case "in_construction":
        return queryData2.inConstruction

      case "for_living":
        return queryData3.forLiving

      case "high_standard":
        return queryData2.highStandard

      case "with_tenants":
        return queryData3.widthTenants

      default:
        return queryData.notReported
    }
  }

  const garageSwitch = garage => {
    switch (garage) {
      case "detached":
        return queryData3.detached

      case "in_the_building":
        return queryData3.inTheBuilding

      case "not_available":
        return queryData3.notAvailable

      default:
        return queryData.notReported
    }
  }

  const heatingSwitch = heatingType => {
    switch (heatingType) {
      case "co_carbon":
        return queryData3.coCarbon

      case "co_gas":
        return queryData3.coGas

      case "co_electric":
        return queryData3.coElectric

      case "co_municipal":
        return queryData3.coMunicipal

      case "oil":
        return queryData3.oil

      case "lack":
        return queryData.lack

      case "coal_stove":
        return queryData3.coalStove

      case "storefront":
        return queryData3.storefront

      case "furnance":
        return queryData3.furnance

      case "fireplace":
        return queryData3.fireplace

      default:
        return queryData.notReported
    }
  }

  const sewerageSwitch = state => {
    switch (state) {
      case "lack":
        return

      case "septic_tank":
        return lang === "pl" ? " szambo, " : " septic tank, "

      case "sewage_system":
        return lang === "pl" ? " kanalizacja miejska, " : " sewerage system, "

      default:
        return
    }
  }

  return (
    <Main>
      <FakeScrollBox id="info" />
      <StyledTitle>{queryData.informations}:</StyledTitle>

      {address && (
        <SpanBox>
          <SubTitle>{queryData.location}:</SubTitle>
          <Value>{address}</Value>
        </SpanBox>
      )}

      {data.baseData.addDate && (
        <SpanBox>
          <SubTitle>{queryData.addDate}:</SubTitle>
          <Value>{data.baseData.addDate}</Value>
        </SpanBox>
      )}

      {data.baseData.propertyType && (
        <SpanBox>
          <SubTitle>{queryData.propertyType}:</SubTitle>
          <Value>{propertyTypeSwitch(data.baseData.propertyType)}</Value>
        </SpanBox>
      )}

      {data.buildingInfo.yearOfBuild && (
        <SpanBox>
          <SubTitle>{queryData.yearBuild}:</SubTitle>
          <Value>{data.buildingInfo.yearOfBuild}</Value>
        </SpanBox>
      )}

      {data.buildingInfo.houseType && (
        <SpanBox>
          <SubTitle>{queryData.houseType}:</SubTitle>
          <Value>{houseTypeSwitch(data.buildingInfo.houseType)}</Value>
        </SpanBox>
      )}

      {data.buildingInfo.floorage && (
        <SpanBox>
          <SubTitle>{queryData2.houseFloorage}:</SubTitle>
          <Value>{data.buildingInfo.floorage}</Value>
        </SpanBox>
      )}

      {data.buildingInfo.roomsCount > 0 && (
        <SpanBox>
          <SubTitle>{queryData.roomsCount}:</SubTitle>
          <Value>{data.buildingInfo.roomsCount}</Value>
        </SpanBox>
      )}

      {data.buildingInfo.floorCount > 0 && (
        <SpanBox>
          <SubTitle>{queryData.floorTotal}:</SubTitle>
          <Value>{data.buildingInfo.floorCount}</Value>
        </SpanBox>
      )}

      {data.buildingInfo.buildingCondition && (
        <SpanBox>
          <SubTitle>{queryData2.buildingCondition}:</SubTitle>
          <Value>
            {buildingConditionSwitch(data.buildingInfo.buildingCondition)}
          </Value>
        </SpanBox>
      )}

      {data.buildingInfo.bathroomCount > 0 && (
        <SpanBox>
          <SubTitle>{queryData2.bathroomCount}:</SubTitle>
          <Value>{data.buildingInfo.bathroomCount}</Value>
        </SpanBox>
      )}

      {data.buildingInfo.bathroomCondition && (
        <SpanBox>
          <SubTitle>{queryData2.bathroomCondition}:</SubTitle>
          <Value>
            {bathroomConditionSwitch(data.buildingInfo.bathroomCondition)}
          </Value>
        </SpanBox>
      )}

      {data.buildingInfo.heating && (
        <SpanBox>
          <SubTitle>{queryData2.heatingType}:</SubTitle>
          <Value>{heatingSwitch(data.buildingInfo.heating)}</Value>
        </SpanBox>
      )}

      {data.buildingInfo.garage && (
        <SpanBox>
          <SubTitle>{queryData.garage}:</SubTitle>
          <Value>{garageSwitch(data.buildingInfo.garage)}</Value>
        </SpanBox>
      )}

      {data.landData.floorage && (
        <SpanBox>
          <SubTitle>{queryData2.landFloorage}:</SubTitle>
          <Value>{data.landData.floorage}</Value>
        </SpanBox>
      )}

      {data.landData.landType && (
        <SpanBox>
          <SubTitle>{queryData3.landType}</SubTitle>
          <Value>{landTypeSwitch(data.landData.landType)}</Value>
        </SpanBox>
      )}

      {data.landData.length > 0 && (
        <SpanBox>
          <SubTitle>{queryData2.landLength}:</SubTitle>
          <Value>{`${data.landData.length} m`}</Value>
        </SpanBox>
      )}

      {data.landData.width > 0 && (
        <SpanBox>
          <SubTitle>{queryData2.landWidth}:</SubTitle>
          <Value>{`${data.landData.width} m`}</Value>
        </SpanBox>
      )}

      {data.landData.fence && (
        <SpanBox>
          <SubTitle>{queryData2.fence}:</SubTitle>
          <Value>{fenceTypeSwitch(data.landData.fence)}</Value>
        </SpanBox>
      )}

      {data.landData.accessRoad && (
        <SpanBox>
          <SubTitle>{queryData2.accessRoad}:</SubTitle>
          <Value>{accessRoadSwitch(data.landData.accessRoad)}</Value>
        </SpanBox>
      )}

      {(data.landData.water !== "lack") |
        data.landData.gas |
        data.landData.threePhase |
        data.landData.sewerage && (
        <SpanBox>
          <SubTitle>{queryData2.media}:</SubTitle>
          <Value>
            {data.landData.water === "lack" ? "" : `${queryData.water},`}
            {data.landData.threePhase && ` ${queryData.threePhase},`}
            {sewerageSwitch(data.landData.sewerage)}
            {data.landData.gas && ` ${queryData.gas}`}
          </Value>
        </SpanBox>
      )}
    </Main>
  )
}

export default House
