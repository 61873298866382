import styled from "styled-components"

export const Main = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 90px;
`

export const Wrapper = styled.div`
  width: 83.3%;
  margin-top: 64px;
  display: flex;
  justify-content: space-between;
  background: #030303;

  @media (max-width: 1149px) {
    width: 82.5%;
  }

  @media (max-width: 799px) {
    width: 88.888%;
  }
`

export const LeftSide = styled.div`
  width: 75%;
  height: 100%;

  @media (max-width: 1149px) {
    width: 100%;
  }
`

export const RightSide = styled.div`
  max-width: 25%;
  min-height: 1600px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;

  @media (max-width: 1149px) {
    width: 0;
  }
`

export const Title = styled.p`
  font-weight: 700;
  font-size: 2.3rem;
  line-height: 3.45rem;
  letter-spacing: 0.06em;
  background: linear-gradient(
    90deg,
    #ce9638 0.35%,
    #eae69c 17.05%,
    #b6872d 114.34%
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  grid-column-start: 1;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 2;
`

export const Title2 = styled(Title)`
  background: linear-gradient(
    269.66deg,
    #ce9638 -15.04%,
    #eae69c 9.04%,
    #ce9638 56.57%,
    #eae69c 106.63%
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
`
export const Title3 = styled(Title)`
  background: linear-gradient(
    266.91deg,
    #ce9638 -29.35%,
    #eae69c 17.05%,
    #b6872d 114.34%
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
`

export const FakeScrollBox = styled.div`
  width: 1px;
  height: 1px;
  opacity: 0;
  position: absolute;
  top: -8.5rem;
`
