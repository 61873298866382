import React from "react"
import {
  Main,
  UnderNav,
  GoldBar,
  PriceBox,
  Wrapper,
  AddressBox,
  PhoneAddressBox,
  PhoneGoldBar,
} from "./style"

const Header = ({ data, handleClick }) => {
  const address = `${data.baseData.address.address ? data.baseData.address.address + "," : ""} ${
    data.baseData.address.district ? data.baseData.address.district + "," : ""
  } ${
    data.baseData.address.city ? data.baseData.address.city + "," : ""
  } ${data.baseData.address.state ? data.baseData.address.state : ""}`

  return (
    <>
      <Main>
        <UnderNav
          image={data.baseData.meta && data.baseData.meta.ogPictureUrl}
          loading="lazy"
          onClick={() => handleClick(0)}
        ></UnderNav>
      </Main>
      <PhoneAddressBox>
        <p>{data.baseData.meta ? data.baseData.meta.title : "Nie podano"}</p>
        <p>{data ? address : "Nie podano"}</p>
      </PhoneAddressBox>
      <PhoneGoldBar>
        <Wrapper>
          <AddressBox>
            <p>
              {data.baseData.meta ? data.baseData.meta.title : "Nie podano"}
            </p>
            <p>{data ? address : "Nie podano"}</p>
          </AddressBox>
          <PriceBox>
            <p>
              {data.baseData
                ? `${Intl.NumberFormat("pl").format(data.baseData.price)} ${
                    data.baseData.transaction === "rent" ? "PLN/mc" : "PLN"
                  }`
                : "Nie podano"}
            </p>
          </PriceBox>
          <PriceBox>
            <p>
              {data?.baseData?.propertyType
                ? data.baseData.propertyType === "flat"
                  ? `${Intl.NumberFormat("pl").format(data.floorage)} m2`
                  : data.baseData.propertyType === "land"
                  ? `${Intl.NumberFormat("pl").format(
                      data.landData.floorage
                    )} m2`
                  : data.baseData.propertyType === "house"
                  ? `${Intl.NumberFormat("pl").format(
                      data?.buildingInfo.floorage
                    )} m2`
                  : data.baseData.propertyType === "usable_locale"
                  ? `${Intl.NumberFormat("pl").format(data?.floorage)} m2`
                  : "Nie podano"
                : "Nie podano"}
            </p>
            <p>
              {data.baseData
                ? `${Intl.NumberFormat("pl").format(
                    data.baseData.pricePerBuildingMeter
                      ? data.baseData.pricePerBuildingMeter
                      : data.baseData.pricePerLandMeter
                  )} PLN/m2`
                : "Nie podano"}
            </p>
          </PriceBox>
        </Wrapper>
      </PhoneGoldBar>
      <GoldBar>
        <Wrapper>
          <AddressBox>
            <p>
              {data.baseData.meta ? data.baseData.meta.title : "Nie podano"}
            </p>
            <p>{data ? address : "Nie podano"}</p>
          </AddressBox>

          <div>
            <PriceBox>
              <p>Powierzchnia</p>
              <p>
                {data?.baseData?.propertyType
                  ? data.baseData.propertyType === "flat"
                    ? `${Intl.NumberFormat("pl").format(data.floorage)} m2`
                    : data.baseData.propertyType === "land"
                    ? `${Intl.NumberFormat("pl").format(
                        data.landData.floorage
                      )} m2`
                    : data.baseData.propertyType === "house"
                    ? `${Intl.NumberFormat("pl").format(
                        data?.buildingInfo.floorage
                      )} m2`
                    : data.baseData.propertyType === "usable_locale"
                    ? `${Intl.NumberFormat("pl").format(data?.floorage)} m2`
                    : "Nie podano"
                  : "Nie podano"}
              </p>
            </PriceBox>
            <PriceBox>
              <p>
                {data.baseData
                  ? `${Intl.NumberFormat("pl").format(data.baseData.price)} ${
                      data.baseData.transaction === "rent" ? "PLN/mc" : "PLN"
                    }`
                  : "Nie podano"}
              </p>
              <p>
                {data.baseData
                  ? `${Intl.NumberFormat("pl").format(
                      data.baseData.pricePerBuildingMeter
                        ? data.baseData.pricePerBuildingMeter
                        : data.baseData.pricePerLandMeter
                    )} PLN/m2`
                  : "Nie podano"}
              </p>
            </PriceBox>
          </div>
        </Wrapper>
      </GoldBar>
    </>
  )
}

export default Header
