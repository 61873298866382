import React from "react"
import { Main, SpanBox, SubTitle, Value, StyledTitle } from "../style"
import { FakeScrollBox } from "../../style"
import { useIntl } from "gatsby-plugin-react-intl"

const Land = ({ data, queryData, queryData2, queryData3 }) => {
  const intl = useIntl()
  const lang = intl.locale
  const address = `${data.baseData.address.address ? data.baseData.address.address + "," : ""} ${
    data.baseData.address.district ? data.baseData.address.district + "," : ""
  } ${
    data.baseData.address.city ? data.baseData.address.city + "," : ""
  } ${data.baseData.address.state ? data.baseData.address.state : ""}`

  const accessRoadSwitch = accessRoadType => {
    switch (accessRoadType) {
      case "asphalt":
        return queryData.asphalt

      case "hardened":
        return queryData.hardened

      case "field":
        return queryData.field

      case "lack":
        return queryData.lack

      default:
        return queryData.notReported
    }
  }

  const propertyTypeSwitch = propertyType => {
    switch (propertyType) {
      case "flat":
        return queryData.flat

      case "house":
        return queryData.house

      case "land":
        return queryData.land

      case "office_space":
        return queryData.officeSpace

      case "usable_locale":
        return queryData.usableLocale

      default:
        return queryData.notReported
    }
  }

  const landTypeSwitch = landType => {
    switch (landType) {
      case "construction":
        return queryData2.construction

      case "agricultural":
        return queryData2.agricultural

      case "investment":
        return queryData2.investment

      case "services":
        return queryData2.services

      case "recreational":
        return queryData2.recreational

      case "forest":
        return queryData2.forest

      case "habitat":
        return queryData2.habitat

      case "farm":
        return queryData2.farm

      case "craft":
        return queryData2.craft

      case "agro_construction":
        return queryData2.agroConstruction

      default:
        return queryData.notReported
    }
  }

  const fenceTypeSwitch = fenceType => {
    switch (fenceType) {
      case "metal":
        return queryData2.metal

      case "mesh":
        return queryData2.mesh

      case "brick":
        return queryData2.brick

      case "concrete":
        return queryData2.concrete

      case "wooden":
        return queryData2.wooden

      case "hedge":
        return queryData2.hedge

      case "mixed":
        return queryData2.mixed

      case "none":
        return queryData2.none

      default:
        return queryData.notReported
    }
  }

  const sewerageSwitch = state => {
    switch (state) {
      case "lack":
        return

      case "septic_tank":
        return lang === "pl" ? " szambo, " : " septic tank, "

      case "sewage_system":
        return lang === "pl" ? " kanalizacja miejska, " : " sewerage system, "

      default:
        return
    }
  }

  return (
    <Main>
      <FakeScrollBox id="info" />
      <StyledTitle>{queryData.informations}:</StyledTitle>

      {address && (
        <SpanBox>
          <SubTitle>{queryData.location}:</SubTitle>
          <Value>{address}</Value>
        </SpanBox>
      )}

      {data.baseData.addDate && (
        <SpanBox>
          <SubTitle>{queryData.addDate}:</SubTitle>
          <Value>{data.baseData.addDate}</Value>
        </SpanBox>
      )}

      {data.baseData.propertyType && (
        <SpanBox>
          <SubTitle>{queryData.propertyType}:</SubTitle>
          <Value>{propertyTypeSwitch(data.baseData.propertyType)}</Value>
        </SpanBox>
      )}

      {data.landData.floorage && (
        <SpanBox>
          <SubTitle>{queryData2.landFloorage}:</SubTitle>
          <Value>{data.landData.floorage}</Value>
        </SpanBox>
      )}

      {data.landData.landType && (
        <SpanBox>
          <SubTitle>{queryData3.landType}</SubTitle>
          <Value>{landTypeSwitch(data.landData.landType)}</Value>
        </SpanBox>
      )}

      {data.landData.length && (
        <SpanBox>
          <SubTitle>{queryData2.landLength}:</SubTitle>
          <Value>{`${data.landData.length} m`}</Value>
        </SpanBox>
      )}

      {data.landData.width && (
        <SpanBox>
          <SubTitle>{queryData2.landWidth}:</SubTitle>
          <Value>{`${data.landData.width} m`}</Value>
        </SpanBox>
      )}

      {data.landData.fence && (
        <SpanBox>
          <SubTitle>{queryData2.fence}:</SubTitle>
          <Value>{fenceTypeSwitch(data.landData.fence)}</Value>
        </SpanBox>
      )}

      {data.landData.accessRoad && (
        <SpanBox>
          <SubTitle>{queryData2.accessRoad}:</SubTitle>
          <Value>{accessRoadSwitch(data.landData.accessRoad)}</Value>
        </SpanBox>
      )}

      {(data.landData.water !== "lack") |
        data.landData.gas |
        data.landData.threePhase |
        data.landData.sewerage && (
        <SpanBox>
          <SubTitle>{queryData2.media}:</SubTitle>
          <Value>
            {data.landData.water === "lack" ? "" : `${queryData.water},`}
            {data.landData.threePhase && ` ${queryData.threePhase},`}
            {sewerageSwitch(data.landData.sewerage)}
            {data.landData.gas && ` ${queryData.gas}`}
          </Value>
        </SpanBox>
      )}
    </Main>
  )
}

export default Land
