import styled from "styled-components"
import { GoldButton } from "../../../SearchComponent/style"
import { Link } from "gatsby-plugin-react-intl"

export const StyledLink = styled(Link)`
  flex: 1;
`

export const Main = styled.div`
  width: 100%;
  margin-top: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;

  @media (max-width: 1149px) {
    height: auto;
  }
`

export const Wrapper = styled.div`
  width: 83.3%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;

  a {
    flex: 1;
  }

  @media (max-width: 1149px) {
    width: 82.5%;
    gap: 20px;
  }

  @media (max-width: 799px) {
    width: 88.888%;
  }
`

export const BlackButton = styled(GoldButton)`
  background: #111111;
  color: #fdfdfd;
  flex: 1;
  width: 100%;

  :hover {
    background: #111111;
  }

  @media (max-width: 1149px) {
    flex: 1;
  }
`

export const StyledGoldButton = styled(GoldButton)`
  flex: 1;
  width: 100%;
  @media (max-width: 1149px) {
    width: 100%;
  }
`
