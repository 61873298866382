import React from "react"
import { Main, SpanBox, SubTitle, Value, StyledTitle } from "../style"
import { FakeScrollBox } from "../../style"

const Local = ({ data, queryData, queryData2, queryData3 }) => {
  const address = `${data.baseData.address.address ? data.baseData.address.address + "," : ""} ${
    data.baseData.address.district ? data.baseData.address.district + "," : ""
  } ${
    data.baseData.address.city ? data.baseData.address.city + "," : ""
  } ${data.baseData.address.state ? data.baseData.address.state : ""}`

  const entranceSwitch = entranceType => {
    switch (entranceType) {
      case "street":
        return queryData3.street

      case "stairway":
        return queryData3.stairway

      case "yard":
        return queryData3.yard

      default:
        return queryData.notReported
    }
  }

  const propertyTypeSwitch = propertyType => {
    switch (propertyType) {
      case "flat":
        return queryData.flat

      case "house":
        return queryData.house

      case "land":
        return queryData.land

      case "office_space":
        return queryData.officeSpace

      case "usable_locale":
        return queryData.usableLocale

      default:
        return queryData.notReported
    }
  }

  const booleanSwitch = data => {
    switch (data) {
      case true:
        return queryData.yes

      case false:
        return queryData.no

      default:
        return queryData.notReported
    }
  }

  const basementSwitch = basementType => {
    switch (basementType) {
      case "full":
        return queryData3.full

      case "partial":
        return queryData3.partial

      case "none":
        return queryData2.none

      default:
        return queryData.notReported
    }
  }

  return (
    <Main>
      <FakeScrollBox id="info" />
      <StyledTitle>{queryData.informations}:</StyledTitle>

      {address && (
        <SpanBox>
          <SubTitle>{queryData.location}:</SubTitle>
          <Value>{address}</Value>
        </SpanBox>
      )}

      {data.baseData.addDate && (
        <SpanBox>
          <SubTitle>{queryData.addDate}:</SubTitle>
          <Value>{data.baseData.addDate}</Value>
        </SpanBox>
      )}

      {data.baseData.propertyType && (
        <SpanBox>
          <SubTitle>{queryData.propertyType}:</SubTitle>
          <Value>{propertyTypeSwitch(data.baseData.propertyType)}</Value>
        </SpanBox>
      )}

      {data.yearOfBuild && (
        <SpanBox>
          <SubTitle>{queryData.yearBuild}:</SubTitle>
          <Value>{data.yearOfBuild}</Value>
        </SpanBox>
      )}

      {data.entrance && (
        <SpanBox>
          <SubTitle>wejście:</SubTitle>
          <Value>{entranceSwitch(data.entrance)}</Value>
        </SpanBox>
      )}

      {data.floorage && (
        <SpanBox>
          <SubTitle>{queryData3.localFloorage}:</SubTitle>
          <Value>{data.floorage}</Value>
        </SpanBox>
      )}

      {/* <SpanBox>
        <SubTitle>charakter lokalu:</SubTitle>
        <Value>{characterSwitch(data.character)}</Value>
      </SpanBox> */}

      {data.shopWindow && (
        <SpanBox>
          <SubTitle>{queryData3.shopWindow}:</SubTitle>
          <Value>{booleanSwitch(data.shopWindow)}</Value>
        </SpanBox>
      )}

      {data.toilet && (
        <SpanBox>
          <SubTitle>{queryData3.toilet}</SubTitle>
          <Value>{booleanSwitch(data.toilet)}</Value>
        </SpanBox>
      )}

      {data.basement && (
        <SpanBox>
          <SubTitle>{queryData3.basement}:</SubTitle>
          <Value>{basementSwitch(data.basement)}</Value>
        </SpanBox>
      )}

      {data.elevator && (
        <SpanBox>
          <SubTitle>{queryData3.elevator}:</SubTitle>
          <Value>{booleanSwitch(data.elevator)}</Value>
        </SpanBox>
      )}

      {(data.water !== "lack") |
        data.gas |
        data.threePhase |
        data.installationAirConditioning |
        data.heating |
        (data.sewerage !== "lack") && (
        <SpanBox>
          <SubTitle>{queryData2.media}:</SubTitle>
          <Value>
            {data.water === "lack" ? "" : `${queryData.water},`}
            {data.threePhase && " siła,"}
            {data.sewerage === "lack" ? "" : ` ${queryData.sewerage},`}
            {data.installationAirConditioning &&
              ` ${queryData.installationAirConditioning},`}
            {data.heating && ` ${queryData3.heating},`}
            {data.gas && ` ${queryData.gas}`}
          </Value>
        </SpanBox>
      )}
    </Main>
  )
}

export default Local
