import styled from "styled-components"
import { Title2 } from "../style"

export const Main = styled.div`
  width: 100%;
  display: grid;
  row-gap: 20px;
  display: flex;
  flex-wrap: wrap;
  position: relative;

  @media (max-width: 1149px) {
    margin-top: 125px;
  }
  @media (max-width: 799px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`

export const SpanBox = styled.div`
  width: 33.3%;
  display: flex;
  flex-direction: column;

  @media (max-width: 799px) {
    width: 100%;
  }

  &:nth-of-type(1) {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 3;
  }
  &:nth-of-type(2) {
    grid-column-start: 5;
    grid-column-end: 9;
    grid-row-start: 2;
    grid-row-end: 3;
  }
  &:nth-of-type(3) {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 3;
    grid-row-end: 4;
  }
  &:nth-of-type(4) {
    grid-column-start: 5;
    grid-column-end: 9;
    grid-row-start: 3;
    grid-row-end: 4;
  }
  &:nth-of-type(5) {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 4;
    grid-row-end: 5;
  }
  &:nth-of-type(6) {
    grid-column-start: 5;
    grid-column-end: 9;
    grid-row-start: 4;
    grid-row-end: 5;
  }
`
export const SubTitle = styled.p`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 2.1rem;
  letter-spacing: 0.06em;
  color: #666;
`

export const Value = styled(SubTitle)`
  font-weight: 500;

  color: #fdfdfd;
`

export const StyledTitle = styled(Title2)`
  width: 100%;
`
