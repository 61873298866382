import React, { useState, useEffect } from "react"
import { Main, ImagesBox } from "./style"
import { FakeScrollBox } from "../style"
import { Title } from "../style"

const ImagesComponent = ({ data, photos, handleClick }) => {
  const [images, setImages] = useState([])

  useEffect(() => {
    if (data.baseData.pictures) {
      setImages(data.baseData.pictures)
    }
  }, [])

  return (
    <Main>
      <FakeScrollBox id="images" />
      <Title>{photos}:</Title>
      <ImagesBox>
        {images.map((picture, index) => {
          return (
            <img
              key={picture}
              src={picture}
              alt="zdjęcie"
              onClick={() => handleClick(index)}
            />
          )
        })}
      </ImagesBox>
    </Main>
  )
}

export default ImagesComponent
