import React, { useState, useEffect } from "react"
import Land from "./Land"
import House from "./House"
import Local from "./Local"
import Flat from "./Flat"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-react-intl"

const DetailsComponent = ({ data }) => {
  const intl = useIntl()
  const [queryData, setQueryData] = useState({})
  const [queryData2, setQueryData2] = useState({})
  const [queryData3, setQueryData3] = useState({})
  const isBrowser = typeof window !== "undefined"

  const query = useStaticQuery(graphql`
    query OfferStatesQuery {
      polish: contentfulOffer(node_locale: { eq: "pl-PL" }) {
        addDate
        afterRenovation
        apartament
        apartamentBuilding
        ask_for_contact
        asphalt
        availableFrom
        balcony
        basementFloorage
        call
        description
        download_offer
        field
        flat
        flatFloorage
        floorCurrent
        floorTotal
        forFinishing
        garage
        gas
        hardened
        house
        houseType
        id
        informations
        installationAirConditioning
        internet
        kitchen
        lack
        land
        location
        no
        node_locale
        notReported
        officeSpace
        overallState
        perfect
        propertyType
        renovated
        roomsCount
        sewerage
        skyscraper
        tenement
        threePhase
        toBeRenewed
        underConstruction
        usableLocale
        water
        yearBuild
        yes
      }
      english: contentfulOffer(node_locale: { eq: "en-US" }) {
        addDate
        afterRenovation
        apartament
        apartamentBuilding
        ask_for_contact
        asphalt
        availableFrom
        balcony
        basementFloorage
        call
        description
        download_offer
        field
        flat
        flatFloorage
        floorCurrent
        floorTotal
        forFinishing
        garage
        gas
        hardened
        house
        houseType
        id
        informations
        installationAirConditioning
        internet
        kitchen
        lack
        land
        location
        no
        node_locale
        notReported
        officeSpace
        overallState
        perfect
        propertyType
        renovated
        roomsCount
        sewerage
        skyscraper
        tenement
        threePhase
        toBeRenewed
        underConstruction
        usableLocale
        water
        yearBuild
        yes
      }
      polish2: contentfulOffer2(node_locale: { eq: "pl-PL" }) {
        forRenovation
        good
        highStandard
        toBeRefreshed
        veryGood
        accessRoad
        agricultural
        agroConstruction
        bathroomCondition
        bathroomCount
        brick
        buildingCondition
        closedShell
        concrete
        construction
        craft
        detached
        extremeSegment
        farm
        fence
        forest
        habitat
        twin
        halfTwin
        hedge
        houseFloor
        houseFloorage
        inConstruction
        investment
        landFloorage
        landLength
        landType
        landWidth
        luxury
        manorHouse
        media
        mesh
        metal
        mixed
        middleSegment
        none
        openShell
        recreational
        residence
        serial
        services
        heatingType
      }
      english2: contentfulOffer2(node_locale: { eq: "en-US" }) {
        twin
        heatingType
        forRenovation
        good
        highStandard
        toBeRefreshed
        veryGood
        accessRoad
        agricultural
        agroConstruction
        bathroomCondition
        bathroomCount
        brick
        buildingCondition
        closedShell
        concrete
        construction
        craft
        detached
        extremeSegment
        farm
        fence
        forest
        habitat
        halfTwin
        hedge
        houseFloor
        houseFloorage
        inConstruction
        investment
        landFloorage
        landLength
        landType
        landWidth
        luxury
        manorHouse
        media
        mesh
        metal
        mixed
        middleSegment
        none
        openShell
        recreational
        residence
        serial
        services
      }
      polish3: contentfulOffer3(node_locale: { eq: "pl-PL" }) {
        coCarbon
        coElectric
        coGas
        coMunicipal
        coalStove
        detached
        fireplace
        forLiving
        furnance
        inTheBuilding
        oil
        notAvailable
        storefront
        withTenants
        landType
        basement
        elevator
        entrance
        full
        heating
        localFloorage
        partial
        shopWindow
        stairway
        street
        toilet
        yard
      }
      english3: contentfulOffer3(node_locale: { eq: "en-US" }) {
        coCarbon
        coElectric
        coGas
        coMunicipal
        coalStove
        detached
        fireplace
        forLiving
        furnance
        inTheBuilding
        oil
        notAvailable
        storefront
        withTenants
        landType
        basement
        elevator
        entrance
        full
        heating
        localFloorage
        partial
        shopWindow
        stairway
        street
        toilet
        yard
      }
    }
  `)

  useEffect(() => {
    if (isBrowser) {
      if (intl.locale === "en") {
        setQueryData(query.english)
        setQueryData2(query.english2)
        setQueryData3(query.english3)
      }
      if (intl.locale === "pl") {
        setQueryData(query.polish)
        setQueryData2(query.polish2)
        setQueryData3(query.polish3)
      }
    }
  }, [intl])

  const propertyTypeSwitch = propertyType => {
    switch (propertyType) {
      case "flat":
        return (
          <Flat
            data={data}
            queryData={queryData}
            queryData2={queryData2}
            queryData3={queryData3}
          />
        )

      case "house":
        return (
          <House
            data={data}
            queryData={queryData}
            queryData2={queryData2}
            queryData3={queryData3}
          />
        )

      case "land":
        return (
          <Land
            data={data}
            queryData={queryData}
            queryData2={queryData2}
            queryData3={queryData3}
          />
        )

      case "office_space":
        return (
          <Local
            data={data}
            queryData={queryData}
            queryData2={queryData2}
            queryData3={queryData3}
          />
        )

      case "usable_locale":
        return (
          <Local
            data={data}
            queryData={queryData}
            queryData2={queryData2}
            queryData3={queryData3}
          />
        )

      default:
        return
    }
  }
  return propertyTypeSwitch(data.baseData.propertyType)
}

export default DetailsComponent
