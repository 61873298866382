import React from "react"

const GoogleMapsEmbed = ({ query, loading = "lazy" }) => {
  return (
    <iframe
      src={`https://www.google.com/maps/embed/v1/place?key=${process.env.GATSBY_MAPS_API_KEY}&q=${query}`}
      width="600"
      height="450"
      style={{ border: "none" }}
      allowfullscreen=""
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    ></iframe>
  )
}

export default GoogleMapsEmbed
