import React, { useState, useContext, useEffect } from "react"
import {
  Main,
  AgentImageBox,
  Name,
  AgentDataBox,
  Email,
  Phone,
  StyledGoldButton,
  Title,
  SecondaryButton,
  StyledGoldenButton,
} from "./style"
import agentImage from "../../../images/agent-image.jpg"
import PopUp from "../../PopUp"
import { ResolutionContext } from "../../../contexts/ResolutionContext"
import { useStaticQuery, graphql, StaticQueryDocument } from "gatsby"
import { useIntl } from "gatsby-plugin-react-intl"

const AgentBox = ({ data, agent }) => {
  const isBrowser = typeof window !== "undefined"
  const [isOpen, toggleOpen] = useState(false)
  const [isTablet, isTabletAndPhone, isPhone] = useContext(ResolutionContext)

  const intl = useIntl()
  const [query, setQuery] = useState({})
  const [query2, setQuery2] = useState({})

  const queryy = useStaticQuery(graphql`
    query AgentQuery {
      polish: contentfulPopup(node_locale: { eq: "pl-PL" }) {
        sendMessage
      }
      english: contentfulPopup(node_locale: { eq: "en-US" }) {
        sendMessage
      }

      polish2: contentfulOffer(node_locale: { eq: "pl-PL" }) {
        call
      }
      english2: contentfulOffer(node_locale: { eq: "en-US" }) {
        call
      }
    }
  `)

  useEffect(() => {
    if (isBrowser) {
      if (intl.locale === "en") {
        setQuery(queryy.english)
        setQuery2(queryy.english2)
      }
      if (intl.locale === "pl") {
        setQuery(queryy.polish)
        setQuery2(queryy.polish2)
      }
    }
  }, [intl])

  return (
    <Main isOpen={isOpen}>
      <PopUp
        isOpen={isOpen}
        toggleOpen={toggleOpen}
        agentEmail={data.baseData.agent && data.baseData.agent.email}
      />

      <Title>{agent}</Title>
      <AgentImageBox>
        <img
          src={
            data.baseData?.agent?.pictureUrl === "none"
              ? agentImage
              : data.baseData?.agent?.pictureUrl
          }
          alt="zdjęcie"
        />
      </AgentImageBox>
      <AgentDataBox>
        <Name>{data.baseData.agent && data.baseData.agent.name}</Name>
        {isPhone ? (
          <Phone>
            <a href={`tel:${data.baseData.agent && data.baseData.agent.phone}`}>
              {data.baseData.agent && data.baseData.agent.phone}
            </a>
          </Phone>
        ) : (
          <Phone>{data.baseData.agent && data.baseData.agent.phone}</Phone>
        )}

        <Email>{data.baseData.agent && data.baseData.agent.email}</Email>
      </AgentDataBox>
      <a href={`tel:${data.baseData.agent && data.baseData.agent.phone}`}>
        <StyledGoldButton>{query2.call}</StyledGoldButton>
      </a>

      {isPhone ? (
        <a onClick={() => toggleOpen(true)}>
          <SecondaryButton>
            <span>{query.sendMessage}</span>
          </SecondaryButton>
        </a>
      ) : (
        <a onClick={() => toggleOpen(true)}>
          <StyledGoldenButton>
            <span>{query.sendMessage}</span>
          </StyledGoldenButton>
        </a>
      )}
    </Main>
  )
}

export default AgentBox
