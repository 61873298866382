import React from "react"
import { Main, SpanBox, SubTitle, Value, StyledTitle } from "../style"
import { FakeScrollBox } from "../../style"
import { useIntl } from "gatsby-plugin-react-intl"

const Flat = ({ data, queryData, queryData2, queryData3 }) => {
  const intl = useIntl()
  const lang = intl.locale
  const address = `${data.baseData.address.address ? data.baseData.address.address + "," : ""} ${
    data.baseData.address.district ? data.baseData.address.district + "," : ""
  } ${
    data.baseData.address.city ? data.baseData.address.city + "," : ""
  } ${data.baseData.address.state ? data.baseData.address.state : ""}`

  const propertyTypeSwitch = propertyType => {
    switch (propertyType) {
      case "flat":
        return queryData.flat

      case "house":
        return queryData.house

      case "land":
        return queryData.land

      case "office_space":
        return queryData.officeSpace

      case "usable_locale":
        return queryData.usableLocale

      default:
        return queryData.notReported
    }
  }

  const booleanSwitch = data => {
    switch (data) {
      case true:
        return queryData.yes

      case false:
        return queryData.no

      default:
        return queryData.notReported
    }
  }

  const houseTypeSwitch = flatType => {
    switch (flatType) {
      case "apartment_building":
        return queryData.apartamentBuilding

      case "tenement":
        return queryData.tenement

      case "house":
        return queryData.house

      case "skyscraper":
        return queryData.skyscraper

      case "apartment":
        return queryData.apartament

      default:
        return queryData.notReported
    }
  }

  const overallStateSwitch = state => {
    switch (state) {
      case "after_renovation":
        return queryData.afterRenovation

      case "for_renovation":
        return queryData2.forRenovation

      case "renovated":
        return queryData.renovation

      case "to_be_renewed":
        return queryData.toBeRenewed

      case "perfect":
        return queryData.perfect

      case "under_construction":
        return queryData.underConstruction

      case "for_finishing":
        return queryData.forFinishing

      case "very_good":
        return queryData2.veryGood

      case "high_standard":
        return queryData2.highStandard

      case "to_be_refreshed":
        return queryData2.toBeRefreshed

      case "good":
        return queryData2.good

      default:
        return queryData.notReported
    }
  }

  const sewerageSwitch = state => {
    switch (state) {
      case "lack":
        return

      case "septic_tank":
        return lang === "pl" ? " szambo, " : " septic tank, "

      case "sewage_system":
        return lang === "pl" ? " kanalizacja miejska, " : " sewerage system, "

      default:
        return
    }
  }

  return (
    <Main>
      <FakeScrollBox id="info" />
      <StyledTitle>{queryData.informations}:</StyledTitle>

      {address && (
        <SpanBox>
          <SubTitle>{queryData.location}:</SubTitle>
          <Value>{address}</Value>
        </SpanBox>
      )}

      {data.baseData.addDate && (
        <SpanBox>
          <SubTitle>{queryData.addDate}:</SubTitle>
          <Value>{data.baseData.addDate}</Value>
        </SpanBox>
      )}

      {data.yearOfBuild && (
        <SpanBox>
          <SubTitle>{queryData.yearBuild}:</SubTitle>
          <Value>{data.yearOfBuild}</Value>
        </SpanBox>
      )}

      {data.baseData.propertyType && (
        <SpanBox>
          <SubTitle>{queryData.propertyType}:</SubTitle>
          <Value>{propertyTypeSwitch(data.baseData.propertyType)}</Value>
        </SpanBox>
      )}

      {data.houseType && (
        <SpanBox>
          <SubTitle>{queryData.houseType}:</SubTitle>
          <Value>{houseTypeSwitch(data.houseType)}</Value>
        </SpanBox>
      )}

      {data.floorage && (
        <SpanBox>
          <SubTitle>{queryData.flatFloorage}:</SubTitle>
          <Value>{data.floorage}</Value>
        </SpanBox>
      )}

      {data.roomsCount && (
        <SpanBox>
          <SubTitle>{queryData.roomsCount}:</SubTitle>
          <Value>{data.roomsCount}</Value>
        </SpanBox>
      )}

      {data.floor.current > 0 && (
        <SpanBox>
          <SubTitle>{queryData.floorCurrent}:</SubTitle>
          <Value>{data.floor.current}</Value>
        </SpanBox>
      )}

      {data.floor.total && (
        <SpanBox>
          <SubTitle>{queryData.floorTotal}:</SubTitle>
          <Value>{data.floor.total}</Value>
        </SpanBox>
      )}

      {data.overallState && (
        <SpanBox>
          <SubTitle>{queryData.overallState}:</SubTitle>
          <Value>{overallStateSwitch(data.overallState)}</Value>
        </SpanBox>
      )}

      {data.kitchen && (
        <SpanBox>
          <SubTitle>{queryData.kitchen}:</SubTitle>
          <Value>{booleanSwitch(data.kitchen)}</Value>
        </SpanBox>
      )}

      {data.balcony && (
        <SpanBox>
          <SubTitle>{queryData.balcony}:</SubTitle>
          <Value>{booleanSwitch(data.balcony)}</Value>
        </SpanBox>
      )}

      {data.internet && (
        <SpanBox>
          <SubTitle>{queryData.internet}:</SubTitle>
          <Value>{booleanSwitch(data.internet)}</Value>
        </SpanBox>
      )}

      {data.garage && (
        <SpanBox>
          <SubTitle>{queryData.garage}:</SubTitle>
          <Value>{booleanSwitch(data.garage)}</Value>
        </SpanBox>
      )}

      {data.basementFloorage && (
        <SpanBox>
          <SubTitle>{queryData.basementFloorage}:</SubTitle>
          <Value>{data.basementFloorage}</Value>
        </SpanBox>
      )}

      {(data.water !== "lack") |
        data.gas |
        data.threePhase |
        data.installationAirConditioning |
        data.sewerage && (
        <SpanBox>
          <SubTitle>{queryData2.media}:</SubTitle>
          <Value>
            {data.water === "lack" ? "" : `${queryData.water},`}
            {data.threePhase && ` ${queryData.threePhase},`}
            {sewerageSwitch(data.sewerage)}
            {data.installationAirConditioning &&
              ` ${queryData.installationAirConditioning},`}
            {data.gas && ` ${queryData.gas}`}
          </Value>
        </SpanBox>
      )}

      {data.availableFrom && (
        <SpanBox>
          <SubTitle>{queryData.availableFrom}:</SubTitle>
          <Value>{data.baseData.availableFrom}</Value>
        </SpanBox>
      )}
    </Main>
  )
}

export default Flat
