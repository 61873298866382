import styled from "styled-components"
import { GoldButton } from "../../SearchComponent/style"
import { Title3 } from "../FlatDescription/style"
import { StyledCancelButton } from "../../PopUp/style"

export const SecondaryButton = styled(StyledCancelButton)`
  display: inline-block;
  margin-top: 10px;
  padding: 0 17px;
  -webkit-box-decoration-break: clone;

  @media (max-width: 799px) {
    display: inline-block;
  }
`

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: ${props => (props.isOpen ? "unset" : "sticky")};
  top: 8.5rem;

  @media (max-width: 1149px) {
    display: flex;
    width: 100%;
    flex-direction: column;
    position: unset;
    top: unset;
    justify-content: center;
    align-items: center;
  }
`

export const AgentDataBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;

  @media (max-width: 1149px) {
    justify-content: center;
    align-items: center;
  }
`

export const AgentImageBox = styled.div`
  width: 100%;
  height: 206px;
  display: flex;
  justify-content: center;
  pointer-events: none;
  align-items: center;

  @media (max-width: 1149px) {
    justify-content: center;
    align-items: center;
  }

  img {
    height: 70%;
    pointer-events: none;
    border-radius: 100%;
  }
`

export const Name = styled.p`
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.4rem;
  letter-spacing: 0.05em;
  color: #fdfdfd;
  text-align: center;
`

export const Phone = styled.p`
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.9rem;
  letter-spacing: 0.05em;
  color: #fdfdfd;

  a {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.9rem;
    letter-spacing: 0.05em;
    color: #fdfdfd;
  }
`

export const Email = styled(Phone)``
export const StyledGoldButton = styled(GoldButton)`
  margin-top: 10px;
  min-width: unset;
  padding: 0 30px;
  display: none;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.4rem;
  letter-spacing: 0.06em;
  text-align: center;

  @media (max-width: 799px) {
    display: flex;
    margin-top: 10px;
    width: 221.55px;
    justify-content: center;
    align-items: center;
  }
`

export const Title = styled(Title3)`
  @media (max-width: 1149px) {
    align-self: flex-start;
  }
`

export const StyledGoldenButton = styled(StyledGoldButton)`
  display: unset;
  span {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 2.4rem;
    letter-spacing: 0.06em;
    text-align: center;
  }
`
