import React, { useState, useEffect } from "react"
import Loading from "../components/Loading"
import Seo from "../components/seo"
import Header from "../components/OfferPage/Header"
import FlatDescription from "../components/OfferPage/FlatDescription"
import Layout from "../components/layout"
import {
  SliderMain,
  SliderImageBox,
  SliderSmallImages,
  Arrow,
  ArrowBar,
  CloseButton,
  SmallImage,
} from "../components/OfferPage/FlatDescription/ImagesComponent/style"

const Offer = ({ pageContext }) => {
  const { offer: dataValue } = pageContext
  const [isSliderOpen, toggleSliderOpen] = useState(false)
  const [selectedImage, setSelectedImage] = useState(0)
  const [images, setImages] = useState([])

  const handleClick = index => {
    toggleSliderOpen(true)
    setSelectedImage(index)
  }

  const nextImage = index => {
    if (index === images.length - 1) {
      return setSelectedImage(0)
    }
    setSelectedImage(index + 1)
  }

  const prevImage = index => {
    if (index === 0) {
      return setSelectedImage(images.length - 1)
    }
    setSelectedImage(index - 1)
  }

  useEffect(() => {
    if (isSliderOpen) {
      document.body.style.overflowY = "hidden"
    } else document.body.style.overflowY = "visible"
  }, [isSliderOpen])

  useEffect(() => {
    if (dataValue.baseData.pictures) {
      setImages(dataValue.baseData.pictures)
    }
  }, [])

  return (
    <Layout>
      {!dataValue || !dataValue.id ? (
        <div
          style={{
            background: "#030303",
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading />
        </div>
      ) : (
        <>
          <Seo
            title={
              dataValue.baseData.meta
                ? dataValue.baseData.meta.title
                : "Nie podano"
            }
            offerDescription={dataValue?.baseData?.description}
            offerOgImage={dataValue?.baseData?.meta?.ogPictureUrl}
            keywords={dataValue?.baseData?.meta?.keywords}
            robots='noindex,nofollow'
          />

          {isSliderOpen && (
            <SliderMain>
              <ArrowBar onClick={() => prevImage(selectedImage)}>
                <Arrow>&lt;</Arrow>
              </ArrowBar>
              <SliderImageBox>
                <CloseButton onClick={() => toggleSliderOpen(false)}>
                  <svg
                    version="1.1"
                    id="Warstwa_1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 13.7 13.7"
                  >
                    <polygon
                      id="XMLID_4_"
                      points="13.7,1.1 12.6,0 6.8,5.8 1.1,0 0,1.1 5.8,6.8 0,12.6 1.1,13.7 6.8,7.9 12.6,13.7 13.7,12.6 7.9,6.8 
	"
                    />
                    <text
                      id="XMLID_1_"
                      transform="matrix(1 0 0 1 -29.4905 -5.5738)"
                    ></text>
                  </svg>
                </CloseButton>
                <img src={images[selectedImage]} alt="image" />
                <SliderSmallImages>
                  {images.map((picture, index) => {
                    if (
                      (index >= selectedImage - 2 &&
                        index <= selectedImage + 2) ||
                      (selectedImage === 0 && index <= 4) ||
                      (selectedImage === 1 && index <= 4 && index > 0) ||
                      (selectedImage === images.length - 1 &&
                        index >= images.length - 5) ||
                      (selectedImage === images.length - 2 &&
                        index >= images.length - 5 &&
                        index < images.length - 1)
                    )
                      return (
                        <SmallImage
                          key={picture}
                          src={picture}
                          alt="zdjęcie"
                          onClick={() => handleClick(index)}
                          index={index}
                          selectedImage={selectedImage}
                        />
                      )
                  })}
                </SliderSmallImages>
              </SliderImageBox>
              <ArrowBar onClick={() => nextImage(selectedImage)}>
                <Arrow>&gt;</Arrow>
              </ArrowBar>
            </SliderMain>
          )}

          <Header data={dataValue} handleClick={handleClick} />
          <FlatDescription data={dataValue} handleClick={handleClick} />
        </>
      )}
    </Layout>
  )
}

export default Offer
