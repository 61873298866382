import styled from "styled-components"

export const Main = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 35px auto;
  gap: 20px 20px;
  margin-top: 106px;
  position: relative;

  iframe {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 2;
    grid-row-end: 3;
    width: 100%;
    aspect-ratio: 16/9;
  }
`
