import styled from "styled-components"
import offerBackground from "../../../images/offer-background.jpg"

export const Main = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 8.5rem);
  display: flex;
  flex-direction: column;
  margin-top: 8.5rem;
  align-items: center;
  min-height: 350px;
  max-height: 1080px;

  @media (max-width: 799px) {
    min-height: unset;
    max-height: unset;
    height: auto;
  }
`

export const Wrapper = styled.div`
  width: 83.3%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div:nth-child(2) {
    display: flex;
    flex-direction: row;
    & > div:first-child {
      align-items: center;
      @media (max-width: 1000px) {
        width: 200px;
      }
    }
    & > div:last-child {
      @media (max-width: 1000px) {
        width: 200px;
      }
    }
  }

  @media (max-width: 1149px) {
    width: 82.5%;
  }

  @media (max-width: 799px) {
    width: 88.88%;
  }
`

export const AddressBox = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 799px) {
    display: none;
  }

  p {
    &:nth-child(1) {
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 2.7rem;
      letter-spacing: 0.05em;
      color: #030303;
    }

    &:nth-child(2) {
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 2.1rem;
      letter-spacing: 0.05em;
      color: #030303;
    }
  }
`

export const UnderNav = styled.div`
  position: relative;
  cursor: pointer;
  background-image: ${props =>
          props.image ? `url(${props.image})` : `url(${offerBackground})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  
  @media (max-width: 799px) {
    aspect-ratio: 16/9;
  }
`

export const GoldBar = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    79.38deg,
    #eae69c 0.31%,
    #bb913c 20.79%,
    #eae69c 45.01%,
    #bb913c 64.96%,
    #b6872d 99.69%
  );
  @media (max-width: 799px) {
    display: none;
    height: 44px;
    position: unset;
    bottom: unset;
  }
`

export const PhoneGoldBar = styled(GoldBar)`
  display: none;
  @media (max-width: 799px) {
    display: flex;
    position: unset;
    bottom: unset;
  }

  & > div {
    & > div:nth-child(3) {
      width: 300px;

      & > p:first-child {
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 2.1rem;
        letter-spacing: 0.05em;
        color: #030303;
      }
    }
  }
`

export const PriceBox = styled.div`
  width: 255px;
  height: 66px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  border-left: 1px solid #030303;

  @media (max-width: 1000px) {
    /* width: 200px; */
  }

  @media (max-width: 799px) {
    border-left: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  p {
    &:nth-child(1) {
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 2.7rem;
      letter-spacing: 0.05em;
      color: #030303;
    }

    &:nth-child(2) {
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 2.1rem;
      letter-spacing: 0.05em;
      color: #030303;
    }
  }
`

export const PhoneAddressBox = styled(AddressBox)`
  display: none;
  @media (max-width: 799px) {
    display: flex;
    height: auto;
    width: 88.88%;
    margin: 0 auto;
    flex-direction: column;
    padding: 20px 0;
    gap: 10px;
    justify-content: flex-start;
    align-items: flex-start;

    p {
      &:nth-child(1) {
        color: #c4a14e;
      }

      &:nth-child(2) {
        color: #c4a14e;
      }
    }
  }
`
